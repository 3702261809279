<template>
  <div class="col main_questions_wrapper_container">
    <!-- Questions List  -->
    <!-- //?Large Screens  -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="$store.getters['questionnaire/total']"
      :DataItems="$store.getters['questionnaire/questions']"
      import_url="questions/import"
      @export="Export"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      :loading="$store.getters['questionnaire/usersLoad']"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_questions"
      edit_permission="add_questions"
    >
      <template #columns>
        <el-table-column prop="id" label="#" sortable></el-table-column>
        <el-table-column
          prop="title"
          :label="`${localization('Title')}`"
          width="400"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break:break-word;
                width: 100%;
              "
            >
              {{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="questions_options"
          :label="`${localization('Answers')}`"
          min-width="380"
        >
          <template slot-scope="scope">
            <el-tag
              v-for="(tag, i) in scope.row.questions_options"
              :key="i"
              style="border-radius: 16px; adding: 1px 17px; margin-right: 2px"
            >
              <span>{{ tag }}</span>
            </el-tag>
            <!-- <el-tag
              v-for="(tag, i) in scope.row.questions_options"
              :key="i"
              style="border-radius: 16px; adding: 1px 17px; margin-right: 2px"
            >
              {{ tag }}
            </el-tag> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="pattern"
          :label="`${localization('Pattern')}`"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break:break-word;
                width: 100%;
              "
            >
              {{ scope.row.pattern }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="age_group_id"
          :label="`${localization('Age')}`"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break:break-word;
                width: 100%;
              "
            >
              {{ getAgeGroup(scope.row.age_group_id) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Language"
          :label="`${localization('Language')}`"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break:break-word;
                width: 100%;
              "
            >
              {{ language(languages, scope.row.language) }}
              <!--              {{ (scope.row.language == 1) ? "English" : "Arabic" }}-->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('State')}`"
          width="120"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{ scope.row.removed == 0 ? "active" : "inactive" }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                v-if="hasPermission('show_questions')"
                :to="`/questions/${scope.row.id}`"
                v-tooltip.top-center="`${localization('Show question')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <a
                v-if="hasPermission('edit_questions')"
                v-tooltip.top-center="`${localization('Edit question')}`"
                class="btn btn-warning btn-link btn-xs"
                @click="open_question_dialog(2, scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete question')}`
                    : `${localization('Active question')}`
                "
                v-if="hasPermission('edit_questions')"
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8"
            ><el-select
              v-model="filter.age_group_id"
              :placeholder="`${localization('Age')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.label"
                :value="age.value"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8">
            <el-select
              v-model="filter.pattern"
              :placeholder="`${localization('Pattern')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(pattern, i) in patterns"
                :key="i"
                :label="pattern.label"
                :value="pattern.label"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <div @click="open_question_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Version") }}
        </div>
      </template>
    </server-table>

    <!-- //? Medium & Small Screens  -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="$store.getters['questionnaire/total']"
      :DataItems="$store.getters['questionnaire/questions']"
      import_url="questions/import"
      @export="Export"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      :loading="$store.getters['questionnaire/usersLoad']"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_questions"
      edit_permission="add_questions"
    >
      <template #columns>
        <!-- <el-table-column prop="id" label="#" width="80"></el-table-column> -->
        <el-table-column prop="title" :label="`${localization('Title')}`">
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break:break-word;
                width: 100%;
              "
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              {{ scope.row.title }}
              <div class="d-flex justify-content-between">
                <router-link
                  v-if="hasPermission('show_questions')"
                  :to="`/questions/${scope.row.id}`"
                  v-tooltip.top-center="`${localization('Show question')}`"
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i>
                </router-link>
                <a
                  v-if="hasPermission('edit_questions')"
                  v-tooltip.top-center="`${localization('Edit question')}`"
                  class="btn btn-warning btn-link btn-xs"
                  @click="open_question_dialog(2, scope.row)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  v-tooltip.top-center="
                    scope.row.removed == 0
                      ? `${localization('Delete question')}`
                      : `${localization('Active question')}`
                  "
                  v-if="hasPermission('edit_questions')"
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="pattern"
          label="Pattern"
          sortable
        ></el-table-column>
        <el-table-column prop="age_group_id" label="Age" sortable>
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{ getAgeGroup(scope.row.age_group_id) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Language" label="language" sortable>
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
              "
            >
              {{ language(languages, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="removed" sortable label="State">
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{ scope.row.removed == 0 ? "active" : "inactive" }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <router-link
                :to="`/questions/${scope.row.id}`"
                v-tooltip.top-center="'show Question'"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <a

              v-if="hasPermission('edit_questions')"
                v-tooltip.top-center="'Edit Question'"
                class="btn btn-warning btn-link btn-xs"
                @click="open_question_dialog(2, scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  scope.row.removed == 0 ? 'Delete' : 'Active'
                "

              v-if="hasPermission('edit_questions')"
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column> -->
      </template>
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8"
            ><el-select
              v-model="filter.age_group_id"
              :placeholder="`${localization('Age')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.label"
                :value="age.value"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8">
            <el-select
              v-model="filter.pattern"
              :placeholder="`${localization('Pattern')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(pattern, i) in patterns"
                :key="i"
                :label="pattern.label"
                :value="pattern.label"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <div @click="open_question_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Version") }}
        </div>
      </template>
    </server-table>

    <!--//? Start Add or Edit Question dialog  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Question')}`
          : `${localization('Edit Question')}`
      "
      width="60%"
      :visible.sync="questionDialog"
      top="4vh"
      class="add_question_dialog_wrapper"
    >
      <el-form
        :model="question"
        ref="ruleForm"
        :rules="rules"
        label-position="top"
        @submit.prevent="save"
      >
        <el-form-item
          :label="`${localization('Question')}`"
          label-width="100px"
          prop="title"
        >
          <el-input v-model="question.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Type')}`"
          label-width="100px"
          prop="type"
        >
          <el-select
            v-model="question.type"
            :placeholder="`${localization('Please select type')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in types"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`${localization('Age')}`"
          label-width="100px"
          prop="age_group_id"
        >
          <el-select
            v-model="question.age_group_id"
            :required="true"
            :placeholder="`${localization('Please select Age group')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in ageList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`${localization('Pattern')}`"
          label-width="100px"
          prop="pattern"
        >
          <el-select
            v-model="question.pattern"
            :placeholder="`${localization('Please select a pattern')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in patterns"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`${localization('Language')}`"
          label-width="100px"
          prop="language"
        >
          <el-select
            v-model="question.language"
            :placeholder="`${localization('Please select language')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in languages"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`${localization('Answers')}`"
          style="width: 100%"
          prop="questions_options"
        >
          <el-select
            v-model="question.questions_options"
            :no-data-text="`${localization('Add answer')}`"
            multiple
            filterable
            allow-create
            default-first-option
            class="select-primary"
            style="width: 100%"
          >
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="questionDialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type=""
          @click="save"
          class="
            text-center
            text-capitalize
            save_button
          "
          >{{ localization("Add Version") }}</el-button
        >
      </span>
    </el-dialog>
    <!--//? End Add or Edit Question dialog  -->

    <!--//? Start Add bulk Dialog  -->
    <el-dialog
      :title="`${localization('Add Bulk')}`"
      :visible.sync="add_bulk_dialog"
      top="4vh"
      width="60%"
      :close-on-click-modal="false"
      class="add_question_dialog_wrapper"
    >
      <el-form
        :model="addBulkModel"
        v-loading="isLoading"
        ref="bulkRuleForm"
        :rules="bulkRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :lg="12"
            ><el-form-item
              :label="`${localization('Language')}`"
              prop="language"
            >
              <el-select
                v-model="addBulkModel.language"
                :placeholder="`${localization('Please select language')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in languages"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :lg="12"
            ><el-form-item
              :label="`${localization('Age')}`"
              prop="age_group_id"
            >
              <el-select
                v-model="addBulkModel.age_group_id"
                :placeholder="`${localization('Please select Age group')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" class="row-bg" justify="end">
          <el-col :lg="12">
            <el-form-item class="w-100">
              <el-upload
                class="w-100"
                style="
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
                  grid-gap: 1rem;
                "
                :auto-upload="false"
                :file-list="fileList"
                :on-change="handleUploadChange"
                :on-success="Refresh"
                accept=".xlsx"
                :before-upload="beforeUpload"
                :action="import_src_bulk"
                :data="{
                  language: addBulkModel.language,
                  age_group_id: addBulkModel.age_group_id
                }"
                :disabled="!addBulkModel.language || !addBulkModel.age_group_id"
                ref="upload"
              >
                <el-button
                  @click="validateFileUpload"
                  slot="trigger"
                  class="w-100"
                  type="primary"
                  >{{ localization("Select file") }}</el-button
                >
              </el-upload>
            </el-form-item></el-col
          >
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="add_bulk_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type=""
          class="text-center text-capitalize save_button "
          @click="submitUpload"
          :disabled="isLoading"
          >{{ localization("Submit") }}</el-button
        >
      </div>
    </el-dialog>
    <!--//? End Add bulk Dialog  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";

import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      questionDialog: false,
      filter: {},
      rules: {
        title: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],

        age_group_id: [
          {
            required: true,
            message: "Please select age group",
            trigger: "change"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please select pattern",
            trigger: "change"
          }
        ],
        type: [
          { required: true, message: "Please select type", trigger: "change" }
        ],
        language: [
          {
            required: true,
            message: "Please select language",
            trigger: "change"
          }
        ]
      },
      localSort: { col: "id", order: "DESC" },
      // preserve sorting concept to local component

      type: null,
      question: {},
      languages: [],
      fileList: [],
      ageList: [
        { value: 1, label: "6-12 years old" },
        { value: 2, label: "13 -21 years old" },
        { value: 3, label: "22 or older" },
        { value: 4, label: "ABE" }
      ],
      types: [
        { value: 0, label: "Options" },
        { value: 1, label: "Text" }
      ],
      patterns: [
        { label: "Confluence" },
        { label: "Precision" },
        { label: "Sequence" },
        { label: "Technical Reasoning" }
      ],

      add_bulk_dialog: false,
      addBulkModel: {},
      isLoading: false,
      bulkRules: {
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    import_src_bulk() {
      return `${process.env.VUE_APP_BASE_URL}/questions-language/import`;
    }
  },
  methods: {
    Refresh(query) {
      this.$store
        .dispatch("questionnaire/questions", { query: query })
        .then(_ => {
          this.languages = this.$store.getters["questionnaire/languages"];
        });
      // stop loading after importing bulk
      this.isLoading = false;
      // close add bulk dialog if opened
      if (this.add_bulk_dialog) this.add_bulk_dialog = false;
      // empty file list ofr bulk upload
      this.fileList = [];
    },
    progress(e, file, list) {
      // close import bulk on uploading file
      // this.add_bulk_dialog = false;
      this.isLoading = true;
      this.progress_count = e.percent;
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("questionnaire/AddQuestion", { query: this.question })
              .then(_ => {
                this.Refresh({});
                this.questionDialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Question has been added.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("questionnaire/UpdateQuestion", {
                id: this.question.id,
                query: this.question
              })
              .then(_ => {
                this.Refresh({
                  limit: 4,
                  offset: 0,
                  order_by: this.localSort.col,
                  order_direction: this.localSort.order
                });
                this.questionDialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Question has been updated.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure you want delete this question?`
            : "Are you sure you want Active this question?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("questionnaire/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `` : "",
              text:
                removed == 0
                  ? "Question has been deleted."
                  : "Question has been actived.",
              icon: "success",
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
        }
      });
    },
    open_question_dialog(type, item) {
      this.question = { ...item };
      this.questionDialog = true;
      this.type = type;
    },
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    getAgeGroup(id) {
      switch (id) {
        case 1:
          return "6-12 years old";
        case 2:
          return "13 -21 years old";
        case 3:
          return "22 or older";
        default:
          return "-";
      }
    },
    Export(query) {
      this.$store.dispatch("questionnaire/Export", { query: query });
    },
    Export_Bulk() {
      this.$store.dispatch("questionnaire/ExportBulk");
    },
    beforeUpload(file) {
      // start loading before uploading
      this.isLoading = true;
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    validateFileUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          return true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          this.$refs.upload.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.container--fluid {
  min-height: 80vh !important;
}

::v-deep .el-upload {
  width: 100%;
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_questions_wrapper_container {
  .add_question_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
</style>
